<template>
  <div id="cert-regen">
    <h1>Request Certificate Re-Generation</h1>
    <div class="cert-regen-form">
      <div>
        <div class="submit-success">
          <p v-if="message">
            {{ message }}
          </p>
        </div>
        <div class="submit-errors">
          <p v-if="error">
            {{ error.message }}
          </p>
        </div>
        <validation-observer ref="form" v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <validation-provider rules="required|uuid" name="User ID" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <label for="txtUserID">User ID <fa icon="asterisk" /></label>
                <input id="txtUserID" type="text" :disabled="isBusy" placeholder="Enter User ID" v-model="userID">
                <p v-if="errors.length > 0" class="error">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <validation-provider rules="required|uuid" name="Sitting ID" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <label for="txtSittingID">Sitting ID<fa icon="asterisk" /></label>
                <input id="txtSittingID" type="text" :disabled="isBusy" placeholder="Enter Sitting ID" v-model="sittingID">
                <p v-if="errors.length > 0" class="error">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <validation-provider rules="uuid" name="Order ID" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <label for="orderID">Order ID</label>
                <input id="orderID" type="text" :disabled="isBusy" placeholder="Enter Order ID" v-model="orderID">
                <p v-if="errors.length > 0" class="error">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <p v-if="isBusy" class="busy">
              Please wait..
            </p>
            <div class="form-row">
              <button :disabled="isBusy || invalid" class="es-btn teal">Go</button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CertRegen',
  data: () => ({
    userID: '',
    sittingID: '',
    orderID: '',
    error: null,
    message: null
  }),
  computed: {
    ...mapGetters({
      isBusy: 'ui/isCertBusy'
    })
  },
  methods: {
    ...mapActions({
      requestCertGen: 'ui/requestCertGen'
    }),
    async onSubmit() {
      try {
        this.message = null
        this.error = null
        await this.requestCertGen({
          uid: this.userID,
          sid: this.sittingID,
          oid: this.orderID
        })
        
        this.userID = ''
        this.sittingID = ''
        this.orderID = ''
        this.message = 'Request accepted.'
        this.$refs.form.reset()
      } catch(err) {
        this.error = err
      }
    }
  },
  beforeMount() {
    const { uid, sid, oid } = this.$route.query
    this.userID = uid
    this.sittingID = sid
    this.orderID = oid
  }
}
</script>
<style lang="scss" scoped>
#cert-regen {
  margin-top: 3em;
  & h1 {
    text-align: center;
    margin-bottom: 1.5em;
  }
  & .cert-regen-form {
    width: 400px;
    margin: auto;
    .submit-errors {
      margin: 1.5em 0;
      & p {
        margin: 0;
        color: $cranberry;
        text-align: center;
        font-family: $headline;
      }
    }
    .submit-success {
      margin: 1.5em 0;
      & p {
        margin: 0;
        color: $teal;
        text-align: center;
        font-family: $headline;
      }
    }
    p.busy {
      text-align: center;
      margin: 0;
      font-family: $headline;
    }
    & .form-row {
      margin-bottom: 1em;
      
      & label {
        display: block;
        font-size: 12px;
        cursor: pointer;

        & svg {
          color: $cranberry;
          width: 8px;
          vertical-align: top;
          margin-left: .25em;
        }
      }
      &.error {
        & input {
          border-color: $cranberry;
        }  
      }
      & input {
        @include input;
      }
      & p.error {
        font-size: 14px;
        color: $cranberry;
        margin-top: .25em;
      }
      & button {
        width: 100%;
        margin-top: 2em;
      }
    }
  }
}
</style>